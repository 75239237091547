import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import { TextSection } from "./../styledcomponents/ChapterStyles";

import Layout from "../components/layout";

const ContentsPage = () => {
  const data = useStaticQuery(graphql`
    query toc {
      allMdx {
        edges {
          node {
            frontmatter {
              title
              id
              secret
            }
          }
        }
      }
    }
  `)
    .allMdx.edges.map((x) => x.node.frontmatter)
    .filter((x) => !x.secret)
    .sort((x, y) => {
      if (x.id > y.id) return 1;
      if (x.id < y.id) return -1;
      return 0;
    });
  return (
    <Layout>
      <TextSection>
        <h1>Table of Contents</h1>
        <ul>
          {data.map((chapter, index) => (
            <li key={`chapter${index}`}>
              <Link to={`/chapter/${chapter.id}`}>
                Chapter {chapter.id}: {chapter.title}
              </Link>
            </li>
          ))}
        </ul>
      </TextSection>
    </Layout>
  );
};

export default ContentsPage;
